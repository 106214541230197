.records-container {
    width: 95%;
    max-width: 900px;
    height: 100%;
    padding: 20px;
    margin-left: 40px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #dbdfea;
    overflow: hidden; /* Hides both vertical and horizontal scrollbars */
    display: flex;
    flex-direction: column;
}

.white-space-important {
    white-space: normal !important;
    word-break: "break-word";
}

.table-container {
    background-color: white;
    border-radius: 20px; 
    max-height: 298px;  
    max-width: 100%; 
    overflow-y: auto;  
    position: relative; 
    margin-bottom: 30px;
    width: 100%;
    margin-bottom: 10px; /* Adds spacing above the footer */
}

.table-container::-webkit-scrollbar {
    display: none;  /* For Chrome, Safari, and Opera */
}

.tooltip-row {
    max-width: 200px; /* Limits the width of the row for long text */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
  
.pagination-footer {
    display: flex;
    justify-content: start;
    padding: 10px 0;
    border-top: 1px solid #dbdfea; /* Optional: adds a border for separation */
}

.data-table {
    table-layout: fixed; /* Set table layout to fixed */
    width: 100%; /* Ensure the table takes the full width of the container */
   
}

/* .sc-dmqHEX.jWpMhA .rdt_TableCol_Sortable {
    justify-content: center !important;
} */

.action-buttons {
    display: flex;
    justify-content: left;
    gap: 10px;
}


/* Dark mode styles */
body.dark-mode .records-container {
    background-color: #343a40; /* Dark mode background */
    color: white; /* Dark mode text color */
  }
  
  body.dark-mode .table-container {
    background-color: #495057; /* Dark mode table background */
  }
  
  /* Adjust input and select styles for dark mode */
  body.dark-mode .form-control,
  body.dark-mode .custom-select {
    background-color: #6c757d; /* Dark mode input background */
    color: white; /* Dark mode input text color */
    border: 1px solid #ced4da; /* Dark mode input border */
  }
  
  body.dark-mode .loading-message {
    color: white; /* Dark mode loading message color */
  }
  
  /* Style for tooltip in dark mode */
  body.dark-mode .tooltip-row {
    color: white; /* Dark mode tooltip text color */
  }
  
  /* Style for buttons and dropdowns in dark mode */
  body.dark-mode .btn {
    background-color: #6c757d; /* Dark mode button background */
    color: white; /* Dark mode button text color */
  }
  
  body.dark-mode .dropdown-menu {
    background-color: #495057; /* Dark mode dropdown background */
    color: white; /* Dark mode dropdown text color */
  }

@media (max-width: 768px) {
    .records-container {
        margin-left: 0; /* Remove left margin on small screens */
        width: 100%;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .records-container  {
        width: 90%;
        margin-right: -100px;
    }
}