@media (max-width: 426px) {
    .profileEmail{
        display: inline-block;
        max-width: 150px; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
 
   