/* Style for the notification item wrapper */
.notification-item-wrapper {
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect */
  .notification-item-wrapper:hover {
    background-color: #EEFFEB;
    cursor: pointer;
  }
  