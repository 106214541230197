/* styles.css */
body {
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: your-colors-here;
}

/* WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: thin;
}
.onHover:hover{
  color: #60cf4f;
}
.apps-menu .nk-menu-item:hover em {
  color: #60cf4f;
}
.apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  color: #60cf4f !important;
}

@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  overflow-y: scroll;
  background-color: #f5faf6 !important;
  font-family: "Montserrat", sans-serif !important;
}
.dark-mode .apps-menu .nk-menu-item:hover em {
  color: #60cf4f !important;
}

.dark-mode .nk-menu-icon .icon {
  color: #c3eeeb !important;
}

.dark-mode .apps-menu .nk-menu-item:hover {
  background-color: #2c3e50 !important;
}

.dark-mode .apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  color: #60cf4f !important;
}

.dark-mode .apps-menu .nk-menu-item.active {
  background-color: #2c3e50 !important;
}
