.h4{
    font-size: 1.25rem;
    padding-left: 10px;
}
.mb-4 .form-control-cs {
    margin-right: 20px;
    height: 30px;
    /* margin: 10px 20px 10px 0; */
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdfea;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mb-4 .form-label-cs{
    /* font-weight: bold;
    font-size: 18px; */
    font-size: 0.875rem;
    font-weight: 500;
    color: #344357;
}

.mb-4 .form-control-cs::placeholder{
    padding: 0.4375rem 0;
    font-size: 0.8125rem;
    font-weight: 400;
}

.inp-btn .btn {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    font-weight: bold;
}

.form-check-input {
    /* box-shadow: 0 0 0 0.1em rgba(59, 59, 59, 0.25); */
    font-size: 13px;
}

.form-check-label {
    /* margin-right: 1.5rem; */
    margin-top: -25px;
    font-size: 16px;
}

.form-check{
    margin-left: 1rem;
}

.shadow {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.p-4 {
    padding: 1.5rem; 
}

.mb-4 {
    margin-bottom: 1.5rem; 
}

.flex-fill-form {
    flex-grow: 1;
    gap: 90px;
    display: inline;
    border: 1px solid #dbdfea;
    border-radius: 5px;
    background-color: #fff;
    height: 100%;
    /* width: 100%; */
    max-width: 500px;
}

@media (min-width: 992px) {
    .flex-fill-form {
        width: 20%;
        height: 300px; 
    }
}

@media (max-width: 1024px) {
    .mr-4 {
      margin-right: 0; 
    }
}

  @media (min-width: 1024px) and (max-width: 1280px) {
    .d-flex .flex-fill-form {
        max-width: 500px;
    }
}

@media (max-width: 768px) {
    .d-flex .flex-fill-form {
        max-width: 100%;
    }
}