/////////////////////////////////////////////
////////       SKIN VARIABLES        ////////
/////////////////////////////////////////////

/// USED AS PRIMARY  ///
$accent-100:  		#e2f5f0;
$accent-200:  		#cfeee6;
$accent-300:  		#abe2d3;
$accent-400:  		#55BB46;
$accent-500:  		#55BB46;

$accent-color:  	#55BB46;
$accent-light:  	$accent-300;
$accent-dark:  		#07523d;

///  USED AS TEXT/SECONDARY  ///
$base-color:  		#364a63;
$base-text:  		#526484;
$base-light:  		#8094ae;
$base-dark:  		#1c2b46;


/// USED FOR LIGHT/DARK BG  ///
$white:				#ffffff;
$black: 			#000000;

$lighter:			#f5f6fa; 		// @50 
$light:				#ebeef2; 		// @100
$light-alt:			#e5e9f2; 		// @200
$dark:				#1f2b3a; 		// @900
$darker:			#0f192a; 		// @950


///////////////////////////////////////////
///////////////////////////////////////////

$body-bg:							$lighter;

$header-bg-color:                   $lighter;
$header-border-bottom:				$light-alt;

$header-bg-dark-color:              $darker;
$header-bg-theme-color:        		darken($accent-dark, 2%);

$sidebar-bg-color:					$lighter;
$sidebar-border-color:              $light-alt;

$sidebar-bg-dark-color:			    $darker;
$sidebar-border-dark-color:         lighten($sidebar-bg-dark-color, 10%);

$sidebar-bg-theme-color:			darken($accent-dark, 2%);
$sidebar-border-theme-color:   		lighten($sidebar-bg-theme-color, 10%);

$sidebar-bg-light-color:            $white;
$sidebar-border-light-color:        $light;