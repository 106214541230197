.totalEvent-bg{
    background: linear-gradient(135deg, #56ab2f, #a8e063);}
.totalEvent-bg:hover{
    background: linear-gradient(135deg, #a8e063, #56ab2f);}
.upcoming-bg{
    background: linear-gradient(135deg, #43c6ac, #f8ffae);
}
.upcoming-bg:hover{
    background: linear-gradient(135deg, #f8ffae, #43c6ac);
}
.pastEvent-bg{
    background: linear-gradient(135deg, #11998e, #38ef7d);
}
.pastEvent-bg:hover{
    background: linear-gradient(135deg, #38ef7d, #11998e);
}
.successfullEvent-bg{
    background: linear-gradient(135deg, #00b09b, #96c93d);
}
.successfullEvent-bg:hover{
    background: linear-gradient(135deg, #96c93d, #00b09b);
}
.cancelledEvent-bg{
    background: linear-gradient(135deg, #70e1f5, #ffd194);
}
.cancelledEvent-bg:hover{
    background: linear-gradient(135deg, #ffd194, #70e1f5);
}