.gateway-container{
    width: 96%;
    /* height: 100%; */
    padding: 20px;
    margin-left: 30px;
    border-radius: 5px;
    background-color: #fff;   /* 9cb3f3 */
    border: 1px solid #dbdfea;   /* dbdfea */ 
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.heading{
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 22px;
}

.checkbox-section{
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.checkbox-section input[type="checkbox"]{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.checkbox-label{
    font-size: 16px;
    color: grey;
}

.form-label-pg {
    /* font-weight: bold; */
    color: #7b7b7b;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 550;
}

.gateway-container .form-control-pg {
    width: 170%;
    height: 40px;
    padding: 8px;
    padding-left: 20px;
    font-size: 14px;
    /* border: 1px solid #ccc;
    border-radius: 5px; */
}

.form-label-csk{
    color: #7b7b7b;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 550;
}

/* .form-control-pg-csk {
    width: 0;    
    height: 40px;      
    padding: 8px;     
    padding-left: 20px;
    font-size: 14px;   
    border: 1px solid #ccc; 
    border-radius: 5px;
    background-color: aqua;
} */

@media (max-width: 768px) {
    .gateway-container {
        margin-left: auto;
        margin-right: auto;
        width: 90%; /* Or any width suitable for smaller screens */
    }
}

@media (max-width: 426px){
    .form-control-pg {
        width: 100% !important;
    }
}