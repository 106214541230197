// Roboto
@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('../../../fonts/Roboto-Light.eot#iefix') format('embedded-opentype'),
        url('../../../fonts/Roboto-Light.woff2') format('woff2'),
        url('../../../fonts/Roboto-Light.woff') format('woff'),
        url('../../../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('../../../fonts/Roboto-Regular.eot#iefix') format('embedded-opentype'),
        url('../../../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../../../fonts/Roboto-Regular.woff') format('woff'),
        url('../../../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('../../../fonts/Roboto-Medium.eot#iefix') format('embedded-opentype'),
        url('../../../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../../../fonts/Roboto-Medium.woff') format('woff'),
        url('../../../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Medium'),
        url('../../../fonts/Roboto-Bold.eot#iefix') format('embedded-opentype'),
        url('../../../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../../../fonts/Roboto-Bold.woff') format('woff'),
        url('../../../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}