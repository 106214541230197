.gallery{
    .image {
        &-overlay {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            background-color: $accent-dark;
            border-radius: .25rem;
            font-size: 2.5rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility:hidden;
            .icon{
                color: #fff;
            }
        }
    }
    &-content{
        position: relative;
        &:hover{
            .image-overlay{
                visibility:visible;
                opacity: 0.8;
            }
        } 
    }
}